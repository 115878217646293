<template>
  <div>
    <h1>
      Demand vs Availability
    </h1>
    <div class="d-flex flex-wrap justify-content-center">
      <b-card
        class="product_card m-3"
        :class="{
          'border border-danger': Number(order.productDemand) > Number(order.productAvailable)
        }"
        v-for="order in customerOrders"
        :key="order.key"
      >
        <div class="d-flex mb-2 align-items-center justify-content-between">
          <div class="font-weight-bold">{{ order.productName.slice(0, 25) }}</div>

          <b-button
            class="btn btn-light btn-sm ml-3"
            data-toggle="tooltip"
            title="Delete product"
            @click="deleteProduct(order.key)"
          >
            <font-awesome-icon :icon="['fas', 'trash']" class="mr-1" />
          </b-button>
        </div>
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Product Demanded
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            v-model="order.productDemand"
            :class="{
              'border border-danger': Number(order.productDemand) > Number(order.productAvailable)
            }"
            placeholder="Enter Demand"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="group-name" label-for="input-name">
          <template v-slot:label>
            Product Availability
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-name"
            type="text"
            disabled
            :class="{
              'border border-danger': Number(order.productDemand) > Number(order.productAvailable)
            }"
            v-model="order.productAvailable"
            placeholder="Enter Demand"
          ></b-form-input>
        </b-form-group>
      </b-card>
    </div>
    <div class="m-5 d-flex justify-content-end align-items-center">
      <b-button size="sm" @click="handlePlaceOrder" :disabled="loading" class="btn btn-success mx-2"
        ><span class="spinner spinner-white" v-if="loading"></span>Place order</b-button
      >
      <b-button size="sm" variant="warning" :to="{ path: `/orderFormBoxV2` }">
        <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
      </b-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import router from '@/router';

export default {
  name: 'OrderErrorPage',
  data() {
    return {
      customerOrders: []
    };
  },
  mounted() {
    if (this.tempOrder) {
      this.customerOrders = this.tempOrder?.products?.map(product => {
        const productName = product?.split(':::')[0];
        const quantity = product?.split(':::')[1];
        const articleCode = product?.split(':::')[2];
        const productAvailable = product?.split(':::')[3] ? product?.split(':::')[3] : 0;

        return {
          productName,
          productDemand: quantity,
          productAvailable,
          key: articleCode,
          articleCode
        };
      });
    }
  },
  methods: {
    ...mapActions('order', ['updateTempOrder', 'postOrderMagento']),
    deleteProduct(index) {
      this.customerOrders = this.customerOrders.filter(product => product.key !== index);
      const updatedProducts = this.tempOrder.products.filter(product => {
        const productDetails = product.split(':::');
        return productDetails[2] !== index;
      });
      const abc = this.tempOrder;
      const order = { ...abc, products: updatedProducts };
      this.updateTempOrder({ order });
    },
    handlePlaceOrder() {
      const abc = this.customerOrders.some(product => Number(product.productAvailable) < Number(product.productDemand));
      if (abc) {
        Vue.swal({
          title: 'Sorry, the order demand is not available',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Okay'
        });
      } else {
        // const params = {
        /* eslint-disable camelcase */
        const abc = this.tempOrder;
        const params = {
          email: abc.email,
          name: abc.name,
          mobile: abc.customerMobile,
          payment_method: 'cashondelivery',
          storeId: abc.storeid?.toLowerCase(),
          address: {
            city: abc.city,
            street: abc.street,
            pincode: abc.pincode,
            state: abc.state,
            state_code: 'IN',
            phonenumber: abc.mobile
          },
          items: abc.products.map(product => {
            const product_id = product.split(':::')[2];
            const qty = product.split(':::')[1];
            return {
              product_id,
              qty
            };
          })
        };

        this.postOrderMagento({ params, router, redirectUrl: '/order' });
      }
    }
  },
  computed: {
    ...mapState('order', ['loading', 'tempOrder'])
  },
  watch: {
    customerOrders(newValue) {
      if (newValue === undefined || newValue?.length === 0) {
        router.push('/orderFormBoxV2/new');
      }
    }
  }
};
</script>

<style>
.product_card {
  /* width: 40%; */
  /* margin: auto; */
  border: none;
  margin-bottom: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>
